<template>
  <div class="backlink row">
    <img :src="require('@/assets/back.svg')" />
    <router-link
      :to="{
        name: data.routting,
      }"
      >Voltar para as {{ data.label }}</router-link
    >
  </div>
</template>

<script>
export default {
  name: "BackLink",
  props: {
    data: { type: Object },
  },
};
</script>

<style lang="scss" scoped>
@import "/Index";
</style>
