<template>
  <div>
    <div class="text-block page-detail-text" v-if="content.blockType == 'text'">
      <p v-html="content.blockContent"></p>
    </div>
    <div class="img-block" v-if="content.blockType == 'image'">
      <img
        :src="content.blockContent.imageUrl"
        :alt="content.blockContent.imageAlt"
        :title="content.blockContent.imageTitle"
      />
    </div>
    <div class="video-block" v-if="content.blockType == 'video'">
      <iframe
        :src="content.blockContent"
        frameborder="0"
        allowfullscreen
      ></iframe>
    </div>
  </div>
</template>

<script>
export default {
  name: "TextBlock",
  components: {},
  props: {
    content: { type: Object },
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
@import "/Blocks";
</style>

<style lang="scss">
.text-block {
  .wpb_video_widget {
    iframe {
      aspect-ratio: 16 / 9;
      height: auto !important;
      width: 100% !important;
    }
  }
}
</style>
