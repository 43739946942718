<template>
  <div v-if="type == 'detail-column'">
    <div :class="positionClass + 'page-detail'">
      <h1 v-if="title" class="page-detail-title">{{ title }}</h1>
      <div class="column-body row">
        <div>
          <DynamicBlock v-for="(item, i) in content" :key="i" :content="item" />
        </div>
        <div class="body-image-container red-left">
          <img
            v-if="bodyImage.imageSrc"
            :src="bodyImage.imageSrc"
            :title="bodyImage.imageTitle"
            :alt="bodyImage.imageAlt"
          />
        </div>
      </div>
    </div>
  </div>
  <div v-else :class="positionClass + 'page-detail'">
    <h1 v-if="title" class="page-detail-title">{{ title }}</h1>
    <div v-if="content" class="dynamic-block-container">
      <DynamicBlock v-for="(item, i) in content" :key="i" :content="item" />
    </div>
    <slot></slot>
  </div>
</template>

<script>
import DynamicBlock from "@/components/Blocks/DynamicBlock.vue";

export default {
  name: "IndexInfoBlock",
  props: {
    title: { type: String, default: "" },
    subTitle: { type: String, default: "" },
    bodyText: { type: String, default: "" },
    bodyImage: { type: Object },
    type: { type: String },
    positionClass: { type: String, default: "" },
    content: { type: Array },
  },
  components: {
    DynamicBlock,
  },
};
</script>

<style lang="scss" scoped>
@import "/Index";
</style>
